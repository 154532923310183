@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');


* {
  font-family: 'YourFontName', sans-serif;
}


.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}



.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: #7ac142;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.crossmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke: #ff4d4d;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}


/* Hide default arrows */
.slick-prev, .slick-next {
  display: none !important;
}

.crossmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: #f82d2d;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #f82d2d;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.crossmark__cross {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #ffffff;
    }
}


.custom-underline {
  text-decoration-color: blue;
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it's on top */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  

/* Services Section */
.services-container {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #2563eb transparent;
    scroll-snap-type: x mandatory; /* Smooth snapping behavior */
}

/* Scrollbar for Webkit Browsers */
.services-container::-webkit-scrollbar {
    height: 8px;
    background: transparent;
}

.services-container::-webkit-scrollbar-thumb {
    background-color: #2563eb;
    border-radius: 8px;
}

.services-container::-webkit-scrollbar-track {
    background-color: transparent;
}

/* Service Cards Scroll */
.services-scroll {
    display: flex;
    gap: 1.5rem;
    width: fit-content;
    scroll-snap-align: center; /* Snaps cards to the center */
    scroll-behavior: smooth; /* Smooth scrolling */
}

/* Individual Service Card */
.service-card {
    width: 20rem;
    background: linear-gradient(to bottom right, #f9fafb, #ffffff);
    border: 1px solid #e5e7eb;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    scroll-snap-align: center; /* Ensure card aligns smoothly */
}

.service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.service-card img {
    object-fit: cover;
    width: 100%;
    height: 12rem;
}

.service-details {
    padding: 1rem;
    text-align: center;
}

.service-details h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;
}

.service-card:hover h3 {
    color: #2563eb;
}

.service-details p {
    font-size: 0.875rem;
    color: #4b5563;
}

/* Custom Scrollbar */
.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #2563eb transparent;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #2563eb;
    border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

/* Flex container for 3 cards at a time */
.flex {
    gap: 1.5rem;
}

/* Hover Effect for Title */
.group:hover h3 {
    color: #2563eb;
}

/* vertical scrollbar*/
@keyframes scroll-vertical {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  .animate-scroll-vertical {
    animation: scroll-vertical 20s linear infinite;
  }
  
  
  